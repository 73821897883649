import styled from "styled-components"
import { device } from "../../utils/screenSizes"

export const BreakingNewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 0px;
  left: -10px;
  min-height: 60px;
  bottom: 0px;
  z-index: 10001;
  padding-left: 20px;
  @media ${device.m}, ${device.s} {
    right: 10px;
  }
`

export const BreakingNewsMessageContainer = styled.div`
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  align-items: center;
  padding-right: 17px;
  padding-left: 17px;
  line-height: 1em;
  line-height: 1.3em;
  padding-left: 60px;
  background: white;
  font-size: 24px;
  background-image: ${props =>
    props.isHighPriority
      ? "linear-gradient(180deg, #BF1816 0%, #B1110F 100%)"
      : "linear-gradient(180deg, #FFFFFF 0%, #E0E0E0 100%)"};
  font-family: "Neue Haas Grotesk Bloomberg";

  font-weight: bold;
  color: ${props => (props.isHighPriority ? "white" : "#C61A11")};
  flex: 1;

  @media ${device.m} {
    padding-top: 13px;
  }

  @media ${device.s} {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 70px;
    padding-top: 13px;
  }
`

export const Message = styled.div``

export const BreakingButton = styled.div`
  display: flex;
  padding-right: 17px;
  padding-left: 17px;
  padding-top: 0px;
  font-size: 24px;
  color: white;
  border-left: 1px solid #d64d4f;
  background-image: linear-gradient(180deg, #bf1816 0%, #b1110f 100%);
  font-family: "Neue Haas Grotesk Bloomberg";
  font-weight: bold;
  align-items: center;

  @media ${device.s} {
    padding-top: 13px;
    align-items: start;
  }
  @media ${device.m}, ${device.s} {
    align-items: start;
    padding-top: 13px;
    padding-right: 10px;
    padding-left: 10px;
    border-top-right-radius: 10px;
  }
`
