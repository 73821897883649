import React, { useState, useEffect } from 'react'
import BreakingNewsManager from '../../utils/BreakingNews'
import { parseISO, isAfter, isBefore } from 'date-fns'
import loadable from '@loadable/component'

const Hammer = loadable(() => import('react-hammerjs'))

import {
  BreakingNewsContainer,
  BreakingNewsMessageContainer,
  Message,
  BreakingButton
} from './BreakingNews.styled'

const BreakingNews = () => {
  const [now, setNow] = useState(new Date())

  let message = BreakingNewsManager.getMessage(now)

  useEffect(() => {
    let intervalId = setInterval(() => {
      setNow(new Date())
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  if (message == null) {
    return null
  }

  const options = {
    touchAction: 'compute',
    recognizers: {
      swipe: {
        time: 150,
        threshold: 100
      }
    }
  }

  const handleSwipe = (e) => {
    BreakingNewsManager.endMessage(message.parentId)
  }

  if (
    isAfter(now, parseISO(message.startAt)) &&
    isBefore(now, parseISO(message.endAt))
  ) {
    return (
      <Hammer
        direction={'DIRECTION_ALL'}
        onSwipeDown={handleSwipe}
        onSwipeRight={handleSwipe}
        options={options}
      >
        <BreakingNewsContainer>
          <BreakingButton>عاجل</BreakingButton>
          <BreakingNewsMessageContainer isHighPriority={message.isHighPriority}>
            <Message>{message.text}</Message>
          </BreakingNewsMessageContainer>
        </BreakingNewsContainer>
      </Hammer>
    )
  } else if (isBefore(now, parseISO(message.startAt))) {
    return null
  }

  return null
}

export default BreakingNews
